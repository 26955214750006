<div class="ng-doc-toc-wrapper">
  <div class="ng-doc-toc-container">
    <div class="ng-doc-toc-selection" #selection></div>
    <ul class="ng-doc-toc">
      @for (item of tableOfContent; track item) {
        <li
          ng-doc-toc-element
          [path]="item.path"
          [hash]="item.hash"
          [level]="item.level"
          [selected]="item === activeItem()">
          {{ item.title }}
        </li>
      }
    </ul>
  </div>
</div>
